// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-404-tsx": () => import("./../../../src/templates/404.tsx" /* webpackChunkName: "component---src-templates-404-tsx" */),
  "component---src-templates-admin-tsx": () => import("./../../../src/templates/admin.tsx" /* webpackChunkName: "component---src-templates-admin-tsx" */),
  "component---src-templates-match-tsx": () => import("./../../../src/templates/match.tsx" /* webpackChunkName: "component---src-templates-match-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-reg-tsx": () => import("./../../../src/templates/reg.tsx" /* webpackChunkName: "component---src-templates-reg-tsx" */),
  "component---src-templates-tournament-tsx": () => import("./../../../src/templates/tournament.tsx" /* webpackChunkName: "component---src-templates-tournament-tsx" */)
}

